<template>
  <b-modal id="form_agency" :visible="isAddNewRoleSidebarActive" :title="getTitle()" modal-class="modal-primary"
    content-class="shadow" footer-class="d-flex justify-content-between" body-class="p-0" size="xl" static centered
    @change="(val) => $emit('update:is-add-new-role-sidebar-active', val)" cancel-title="Cancelar" ok-title="Guardar"
    :ok-disabled="formDisabled" @hidden="close" @ok="handleOk" @cancel="close" no-close-on-backdrop ref="form_agency">
    <template>

      <!-- BODY -->
      <validation-observer ref="formData">
        <!-- Form -->
        <b-form class="p-2" ref="formData" @submit.stop.prevent="newForm ? handleSubmit() : edit()">
          <h2 class="text-center">Datos de la agencia</h2>
          <hr>
          <b-row>
            <b-col md="3">
              <!-- Name -->
              <validation-provider #default="{ errors }" name="Nit" rules="required">
                <b-form-group label="Número de nit de la agencia" label-for="nit"
                  :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input id="nit" v-model="formData.nit" :state="errors.length > 0 ? false : null" autofocus trim
                    :disabled="formDisabled" />

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="3">
              <!-- Name -->
              <validation-provider #default="{ errors }" name="Nombre de la agencia" rules="required">
                <b-form-group label="Nombre de la agencia" label-for="name"
                  :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input id="name" v-model="formData.name" :state="errors.length > 0 ? false : null" autofocus trim
                    :disabled="formDisabled" />

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="3">
              <!-- Name -->
              <validation-provider #default="{ errors }" name="Razón social" rules="required">
                <b-form-group label="Razón social" label-for="business_name"
                  :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input id="business_name" v-model="formData.business_name"
                    :state="errors.length > 0 ? false : null" autofocus trim :disabled="formDisabled" />

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="3">
              <!-- Name -->
              <validation-provider #default="{ errors }" name="Descripción" rules="required">
                <b-form-group label="Descripción" label-for="description" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input id="description" v-model="formData.description" :state="errors.length > 0 ? false : null"
                    autofocus trim :disabled="formDisabled" />

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="3">
              <!-- Name -->
              <validation-provider #default="{ errors }" name="Página web">
                <b-form-group label="Página web" label-for="web_page">
                  <b-form-input id="web_page" v-model="formData.web_page" trim :disabled="formDisabled" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="3">
              <!-- Name -->
              <validation-provider #default="{ errors }" name="Departamento" rules="required">
                <b-form-group label="Departamento" label-for="department_id"
                  :class="errors.length > 0 ? 'is-invalid' : null">

                  <v-select id="departdepartment_idment" v-model="formData.department_id" :options="listDespartments"
                    :reduce="val => val.id" label="name" :state="errors.length > 0 ? false : null" autofocus trim clearable
                    :disabled="formDisabled" />

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="3">
              <!-- Name -->
              <validation-provider #default="{ errors }" name="Municipio" rules="required">
                <b-form-group label="Municipio" label-for="municipality_id"
                  :class="errors.length > 0 ? 'is-invalid' : null">
                  <v-select id="municipality_id" v-model="formData.municipality_id" :options="listMunicipalities"
                    :reduce="val => val.id" label="name" :state="errors.length > 0 ? false : null" autofocus trim clearable
                    :disabled="formDisabled" />

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="3">
              <!-- Name -->
              <validation-provider #default="{ errors }" name="Dirección" rules="required">
                <b-form-group label="Dirección" label-for="address" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input id="address" v-model="formData.address" :state="errors.length > 0 ? false : null" autofocus
                    trim :disabled="formDisabled" />

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="3">
              <!-- Name -->
              <validation-provider #default="{ errors }" name="Teléfono" rules="required|digits:10">
                <b-form-group label="Teléfono" label-for="phone_number" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input id="phone_number" v-model="formData.phone_number" :state="errors.length > 0 ? false : null"
                    autofocus trim type="number" :disabled="formDisabled" />

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="3">
              <!-- Name -->
              <validation-provider #default="{ errors }" name="Correo" rules="required|email">
                <b-form-group label="Correo" label-for="email" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input id="email" v-model="formData.email" :state="errors.length > 0 ? false : null" autofocus trim
                    :disabled="formDisabled" />

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="3">
              <!-- Name -->
              <validation-provider #default="{ errors }" name="Representante legal" rules="required">
                <b-form-group label="Representante legal" label-for="legal_representative_name"
                  :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input id="legal_representative_name" v-model="formData.legal_representative_name"
                    :state="errors.length > 0 ? false : null" autofocus trim :disabled="formDisabled" />

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="3">
              <!-- Name -->
              <validation-provider #default="{ errors }" name="Contacto representante" rules="required">
                <b-form-group label="Contacto representante" label-for="legal_representative_phone"
                  :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input id="legal_representative_phone" v-model="formData.legal_representative_phone"
                    :state="errors.length > 0 ? false : null" autofocus trim :disabled="formDisabled" />

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="4">
              <!-- Name -->
              <validation-provider #default="{ errors }" name="Cargo del representante" rules="required">
                <b-form-group label="Cargo del representante" label-for="legal_representative_position"
                  :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input id="legal_representative_position" v-model="formData.legal_representative_position"
                    :state="errors.length > 0 ? false : null" autofocus trim :disabled="formDisabled" />

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- <b-col md="4">
              <validation-provider #default="{ errors }" name="Porcentaje" rules="required">
                <b-form-group label="Porcentaje" label-for="percentage" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input id="percentage" v-model="formData.percentage" :state="errors.length > 0 ? false : null"
                    autofocus trim :disabled="formDisabled" type="number" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col> -->
            <b-col md="4">
              <!-- Name -->
              <validation-provider #default="{ errors }" name="Estado" rules="required">
                <b-form-group label="Estado" label-for="status" :class="errors.length > 0 ? 'is-invalid' : null">
                  <v-select id="status" v-model="formData.status" :options="selectStatus" :reduce="val => val.value"
                    label="label" :state="errors.length > 0 ? false : null" autofocus trim clearable
                    :disabled="formDisabled" />

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="4">
              <validation-provider #default="{ errors }" name="Plan" rules="required">
                <b-form-group label="Plan" label-for="plan" :class="errors.length > 0 ? 'is-invalid' : null">
                  <v-select id="plan" v-model="formData.plan" :options="planOptions" label="name" multiple
                    :state="errors.length > 0 ? false : null" trim clearable />

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

        </b-form>
      </validation-observer>
    </template>

  </b-modal>
</template>

<script>
import {
  BRow, BCol, BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormCheckbox, BFormCheckboxGroup, BButtonToolbar,
} from 'bootstrap-vue'
import es from 'vee-validate/dist/locale/es';
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required, email, digits, integer } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  name: 'FormAgency',
  components: {
    BRow,
    BCol,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BButtonToolbar,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    isAddNewRoleSidebarActive: {
      type: Boolean,
      required: true,
    },
    formDisabled: {
      type: Boolean,
      required: true,
    },
    formDataEdit: {
      type: Object,
      required: required,
    },
  },
  watch: {
    formDataEdit: {
      handler(item) {
        const id = parseInt(item.id) ?? null
        if (id) {
          this.newForm = false
          this.formData = {
            id: item.id,
            nit: item.nit,
            name: item.name,
            business_name: item.business_name,
            description: item.description,
            web_page: item.web_page,
            department_id: item.department.id,
            municipality_id: item.municipality.id,
            address: item.address,
            phone_number: item.phone_number,
            email: item.email,
            legal_representative_name: item.legal_representative_name,
            legal_representative_phone: item.legal_representative_phone,
            legal_representative_position: item.legal_representative_position,
            percentage: item.percentage,
            status: item.status,
            plan: item.plan
          }
        }
      },
    },
    "formData.department_id": {
      handler(val) {
        if (val) {
          this.getMunicipalities()
        } else {
          this.formData.municipality_id = null
        }
      },
    },
  },
  data() {
    return {
      newForm: true,
      formData: {
        id: null,
        nit: null,
        name: '',
        business_name: '',
        description: '',
        web_page: '',
        department_id: null,
        municipality_id: null,
        address: '',
        phone_number: null,
        email: '',
        legal_representative_name: '',
        legal_representative_phone: '',
        legal_representative_position: '',
        percentage: 0,
        status: null,
        plan: null
      },
      selectStatus: [
        { label: 'Activo', value: 1 },
        { label: 'Inactivo', value: 0 },
      ],
      listPermissions: [],
      listDespartments: [],
      listMunicipalities: [],
      planOptions: [],
    }
  },
  created() {
    this.getDepartments()
    this.getPlanOptions()
  },
  methods: {
    deletePlan(e) {
      console.log(e)
    },
    getPlanOptions() {
      this.$http.get('/information/plans').then((response) => {
        this.planOptions = response.data.plans
      })
    },
    getDepartments() {
      this.$http.get('/information/departments').then((response) => {
        this.listDespartments = response.data.departments
      })
    },
    getMunicipalities() {
      if (this.formData.department_id) {
        this.$http.get('/information/municipalities/' + this.formData.department_id).then((response) => {
          this.listMunicipalities = response.data.municipalities
        })
      } else {
        this.listMunicipalities = []
      }
    },
    getTitle() {
      if (this.formData.id != undefined || this.formData.id != undefined) {
        if (this.formDisabled == true) {
          return 'Ver agencia'
        }
        return 'Editar agencia'
      } 
      return 'Añadir agencia'
    },
    edit() {
      localize('es', es);
      this.$refs.formData.validate().then(success => {
        if (success) {
          this.$http.post('/roles/edit', this.formData)
            .then((response) => {
              this.refreshUser()
              this.$emit('fetch-roles')
              this.$emit('update:is-add-new-role-sidebar-active', false)
              this.resetForm()
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Rol editado`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: `Rol ${response.data.role.name} editado con éxito`,
                },
              })
            })
            .catch(error => {
              console.log('error')
            })
        }
      })
    },
    resetForm() {
      localize('es', es);
      this.$refs.formData.reset()
      this.formData = {
        id: null,
        nit: null,
        name: '',
        business_name: '',
        description: '',
        web_page: '',
        department_id: null,
        municipality_id: null,
        address: '',
        phone_number: null,
        email: '',
        legal_representative_name: '',
        legal_representative_phone: '',
        legal_representative_position: '',
        percentage: '',
        status: null,
      }
    },
    close() {
      this.$emit('update:is-add-new-role-sidebar-active', false)
      this.$emit('update:form-disabled', false)
    },
    checkFormValidity() {
      const valid = this.$refs.formData.validate()
      this.nameState = valid
      return valid
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      localize('es', es);
      let uri = null
      let type = null
      if (this.newForm) {
        uri = '/agencies/store'
        type = 'creada'
      }
      if (!this.newForm) {
        uri = '/agencies/update'
        type = 'editada'
      }

      this.$refs.formData.validate().then(success => {
        if (success) {
          this.$http.post(uri, this.formData)
            .then((response) => {
              this.$emit('fetch-roles')
              this.resetForm()
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Agencia ${type} con éxito`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: `Agencia ${response.data.agency.name} ${type} con éxito`,
                },
              })
              this.$refs.form_agency.hide();
            })
            .catch(error => {
              console.log('error')
            })
        }
      })
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
