var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{ref:"form_agency",attrs:{"id":"form_agency","visible":_vm.isAddNewRoleSidebarActive,"title":_vm.getTitle(),"modal-class":"modal-primary","content-class":"shadow","footer-class":"d-flex justify-content-between","body-class":"p-0","size":"xl","static":"","centered":"","cancel-title":"Cancelar","ok-title":"Guardar","ok-disabled":_vm.formDisabled,"no-close-on-backdrop":""},on:{"change":function (val) { return _vm.$emit('update:is-add-new-role-sidebar-active', val); },"hidden":_vm.close,"ok":_vm.handleOk,"cancel":_vm.close}},[[_c('validation-observer',{ref:"formData"},[_c('b-form',{ref:"formData",staticClass:"p-2",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();_vm.newForm ? _vm.handleSubmit() : _vm.edit()}}},[_c('h2',{staticClass:"text-center"},[_vm._v("Datos de la agencia")]),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Nit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label":"Número de nit de la agencia","label-for":"nit"}},[_c('b-form-input',{attrs:{"id":"nit","state":errors.length > 0 ? false : null,"autofocus":"","trim":"","disabled":_vm.formDisabled},model:{value:(_vm.formData.nit),callback:function ($$v) {_vm.$set(_vm.formData, "nit", $$v)},expression:"formData.nit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Nombre de la agencia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label":"Nombre de la agencia","label-for":"name"}},[_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"autofocus":"","trim":"","disabled":_vm.formDisabled},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Razón social","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label":"Razón social","label-for":"business_name"}},[_c('b-form-input',{attrs:{"id":"business_name","state":errors.length > 0 ? false : null,"autofocus":"","trim":"","disabled":_vm.formDisabled},model:{value:(_vm.formData.business_name),callback:function ($$v) {_vm.$set(_vm.formData, "business_name", $$v)},expression:"formData.business_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Descripción","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label":"Descripción","label-for":"description"}},[_c('b-form-input',{attrs:{"id":"description","state":errors.length > 0 ? false : null,"autofocus":"","trim":"","disabled":_vm.formDisabled},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Página web"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Página web","label-for":"web_page"}},[_c('b-form-input',{attrs:{"id":"web_page","trim":"","disabled":_vm.formDisabled},model:{value:(_vm.formData.web_page),callback:function ($$v) {_vm.$set(_vm.formData, "web_page", $$v)},expression:"formData.web_page"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Departamento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label":"Departamento","label-for":"department_id"}},[_c('v-select',{attrs:{"id":"departdepartment_idment","options":_vm.listDespartments,"reduce":function (val) { return val.id; },"label":"name","state":errors.length > 0 ? false : null,"autofocus":"","trim":"","clearable":"","disabled":_vm.formDisabled},model:{value:(_vm.formData.department_id),callback:function ($$v) {_vm.$set(_vm.formData, "department_id", $$v)},expression:"formData.department_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Municipio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label":"Municipio","label-for":"municipality_id"}},[_c('v-select',{attrs:{"id":"municipality_id","options":_vm.listMunicipalities,"reduce":function (val) { return val.id; },"label":"name","state":errors.length > 0 ? false : null,"autofocus":"","trim":"","clearable":"","disabled":_vm.formDisabled},model:{value:(_vm.formData.municipality_id),callback:function ($$v) {_vm.$set(_vm.formData, "municipality_id", $$v)},expression:"formData.municipality_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Dirección","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label":"Dirección","label-for":"address"}},[_c('b-form-input',{attrs:{"id":"address","state":errors.length > 0 ? false : null,"autofocus":"","trim":"","disabled":_vm.formDisabled},model:{value:(_vm.formData.address),callback:function ($$v) {_vm.$set(_vm.formData, "address", $$v)},expression:"formData.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Teléfono","rules":"required|digits:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label":"Teléfono","label-for":"phone_number"}},[_c('b-form-input',{attrs:{"id":"phone_number","state":errors.length > 0 ? false : null,"autofocus":"","trim":"","type":"number","disabled":_vm.formDisabled},model:{value:(_vm.formData.phone_number),callback:function ($$v) {_vm.$set(_vm.formData, "phone_number", $$v)},expression:"formData.phone_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Correo","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label":"Correo","label-for":"email"}},[_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false : null,"autofocus":"","trim":"","disabled":_vm.formDisabled},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Representante legal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label":"Representante legal","label-for":"legal_representative_name"}},[_c('b-form-input',{attrs:{"id":"legal_representative_name","state":errors.length > 0 ? false : null,"autofocus":"","trim":"","disabled":_vm.formDisabled},model:{value:(_vm.formData.legal_representative_name),callback:function ($$v) {_vm.$set(_vm.formData, "legal_representative_name", $$v)},expression:"formData.legal_representative_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Contacto representante","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label":"Contacto representante","label-for":"legal_representative_phone"}},[_c('b-form-input',{attrs:{"id":"legal_representative_phone","state":errors.length > 0 ? false : null,"autofocus":"","trim":"","disabled":_vm.formDisabled},model:{value:(_vm.formData.legal_representative_phone),callback:function ($$v) {_vm.$set(_vm.formData, "legal_representative_phone", $$v)},expression:"formData.legal_representative_phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"Cargo del representante","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label":"Cargo del representante","label-for":"legal_representative_position"}},[_c('b-form-input',{attrs:{"id":"legal_representative_position","state":errors.length > 0 ? false : null,"autofocus":"","trim":"","disabled":_vm.formDisabled},model:{value:(_vm.formData.legal_representative_position),callback:function ($$v) {_vm.$set(_vm.formData, "legal_representative_position", $$v)},expression:"formData.legal_representative_position"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"Estado","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label":"Estado","label-for":"status"}},[_c('v-select',{attrs:{"id":"status","options":_vm.selectStatus,"reduce":function (val) { return val.value; },"label":"label","state":errors.length > 0 ? false : null,"autofocus":"","trim":"","clearable":"","disabled":_vm.formDisabled},model:{value:(_vm.formData.status),callback:function ($$v) {_vm.$set(_vm.formData, "status", $$v)},expression:"formData.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"Plan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{class:errors.length > 0 ? 'is-invalid' : null,attrs:{"label":"Plan","label-for":"plan"}},[_c('v-select',{attrs:{"id":"plan","options":_vm.planOptions,"label":"name","multiple":"","state":errors.length > 0 ? false : null,"trim":"","clearable":""},model:{value:(_vm.formData.plan),callback:function ($$v) {_vm.$set(_vm.formData, "plan", $$v)},expression:"formData.plan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }